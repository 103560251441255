import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Kahoolawe = () => {
  return (
    <Islands activeIsland="kahoolawe">
      <DesignSystem.H1>Kahoolawe (Kaho'olawe)</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Kahoolawe
